export * from './getTimeDateObjectFromString';
import { parse } from 'date-fns';

/**
 * Parse a API formatted date in the form
 * yyyy-MM-dd
 * @param str
 */
export function parseApiDate(str: string): Date {
  return parseApiDatetime(`${str} 00:00:00`);
}

/**
 * Parse a API formatted datetime in the form
 * yyyy-MM-dd HH:mm:ss
 * @param str
 */
export function parseApiDatetime(str: string): Date {
  return parse(str, 'yyyy-MM-dd HH:mm:ss', new Date());
}
