import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const IMAGE_LAYOUTS = {
  '40x40_face': {
    cloudinary: 'upload/q_auto,f_auto,c_scale,w_80,h_80,g_face,c_thumb/r_max/',
    tacticCrop: { width: '80', height: '80' }
  },
  '125x125_face': {
    cloudinary:
      'upload/q_auto,f_auto,c_scale,w_250,h_250,g_face,c_thumb/r_max/',
    tacticCrop: { width: '250', height: '250' }
  },
  '300x300_face': {
    cloudinary:
      'upload/q_auto,f_auto,c_scale,w_600,h_600,g_face,c_thumb/r_max/',
    tacticCrop: { width: '600', height: '600' }
  },
  logo_max_width_300: {
    cloudinary: 'upload/q_auto,f_auto,w_300,h_300,c_limit/',
    tacticCrop: { width: '300', height: '300', fit: 'scale-down' }
  }
};

export function imageUrl(url: string, layout: keyof typeof IMAGE_LAYOUTS) {
  if (url.indexOf('https://res.cloudinary.com/') === 0) {
    return url.replace(/upload\/(.*?)\//i, IMAGE_LAYOUTS[layout].cloudinary);
  }
  if (url.indexOf('https://cdn.gettactic.com/image-crop/') === 0) {
    const parsed = new URL(url);
    parsed.searchParams.set(
      'width',
      IMAGE_LAYOUTS[layout].tacticCrop.width.toString()
    );
    parsed.searchParams.set(
      'height',
      IMAGE_LAYOUTS[layout].tacticCrop.height.toString()
    );
    return parsed.toString();
  }
  if (
    url.indexOf('https://cdn.gettactic.com/organization-logos/') === 0 ||
    url.indexOf('https://cdn.gettactic.com/profile-photos/') === 0
  ) {
    const params = new URLSearchParams({
      ...IMAGE_LAYOUTS[layout].tacticCrop,
      image: url
    });
    return `https://cdn.gettactic.com/image-crop/?${params.toString()}`;
  }
  return url;
}

