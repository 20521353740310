import type {
  AuthenticatedUser,
  IResource,
  Office,
  ResourceType
} from '@gettactic/api';

const ORG_SLUG_REQUIRES_RESERVATION_AFTER_PICKING_WL = Object.freeze([
  'tesla',
  'staging',
  'grammarly',
  'demo'
]);

// Redirects to health questionnaire / survey immediately after creating a reservation
const ORG_SLUG_RESERVATION_SURVEY = Object.freeze([
  'deighton',
  'pictonmahoney'
]);

const ORG_SLUG_PRIVACY_REPORTS = Object.freeze(['altair']);

const ORG_SLUG_NO_VISITOR_REPORTS = Object.freeze(['altair', 'hpe']);

const ORG_SLUG_NO_PAST_RESERVATIONS = Object.freeze(['softserve']);

const ORG_SLUG_RB_FEATURES = Object.freeze([
  'redbull',
  'staging',
  'chatarra',
  'alekhya'
]);

const ORG_SLUG_DOC_FEATURE = Object.freeze([
  'demo',
  'tactic',
  'staging',
  'sergio',
  'alekhya'
]);

const ORG_SLUG_EXTENDED_RESTRICTIONS = Object.freeze([
  'demo',
  'tactic',
  'staging',
  'alekhya',
  'exness',
  'kordamentha'
]);

const ORG_SLUG_MAP_BUILDER_EDITOR_ENABLED = Object.freeze([
  'demo',
  'tactic',
  'staging',
  'gondor',
  'softserve'
]);

/**
 * An array of user IDs that will force the recording of sessions in Sentry Session Replay.
 * */
const FORCE_SESSION_REPLAY_USERS: readonly string[] = Object.freeze([]);

const RESOURCE_ID_VIRTUAL_ROOMS = Object.freeze([
  'reso_af45af60-df41-4a69-aaec-64b01c0a643a', // Tesla - Desk 1
  'reso_ebe95805-38de-4637-84bd-e935b17ca8b2', // Tesla - Desk 2
  'reso_e00f796e-6fc1-4e55-b997-c41a52961ffc', // Tactic - Huddle Room #1
  'reso_6f8dc164-039c-4c64-a72d-f4bdabeb60ef', // Tactic - Huddle Room #2
  'reso_9d4c181a-a599-4ec3-8192-89c7f7e22c90', // Tactic - Huddle Room #3
  'reso_56194b05-2a58-460a-8a06-359029abc2ef' // Tactic - Huddle Room #4,
]);

const ORG_USER_DASH_BETA = Object.freeze([
  'user_4180c132-b8c2-4a31-89b0-b69d6ff19e15',
  'user_1dd31aae-68ad-4e27-8778-5378b861417e'
]);
const ORG_USER_OLD_DASHBOARD_AS_DEFAULT = Object.freeze([
  'user_4180c132-b8c2-4a31-89b0-b69d6ff19e15',
  'user_1dd31aae-68ad-4e27-8778-5378b861417e'
]);

export function isWifiAppEnabled(user?: AuthenticatedUser) {
  return (
    isTacticStaffOrImpersonated(user) ||
    user?.organization?.features.includes('wifi')
  );
}

export function isWorkingLocationsWithOOOFeaturesEnabled(
  user?: AuthenticatedUser
) {
  return user?.organization?.features.includes('leave.management');
}

export function isReservationSurveyEnabled(user?: AuthenticatedUser) {
  return (
    user && ORG_SLUG_RESERVATION_SURVEY.includes(user?.organization?.slug ?? '')
  );
}

export function canUseMapBuilderEditor(user?: AuthenticatedUser) {
  return (
    user &&
    ORG_SLUG_MAP_BUILDER_EDITOR_ENABLED.includes(user?.organization?.slug ?? '')
  );
}

export function isPrivacyReportsEnabled(user?: AuthenticatedUser) {
  return (
    user && ORG_SLUG_PRIVACY_REPORTS.includes(user?.organization?.slug ?? '')
  );
}

export function isNotAllowedToSelectAllOfficesForReports(
  user?: AuthenticatedUser
) {
  return user && ['hpe'].includes(user?.organization?.slug ?? '');
}

export function isVisitorReportDisabled(user?: AuthenticatedUser) {
  return (
    user && ORG_SLUG_NO_VISITOR_REPORTS.includes(user?.organization?.slug ?? '')
  );
}

export function hasEnabledDeleteResource(
  user?: AuthenticatedUser,
  resource?: IResource
) {
  return isTacticStaffOrImpersonated(user) || resource?.type === 'area';
}

export function hasEnabledTacticVirtualRooms(user?: AuthenticatedUser) {
  return isTacticStaffOrImpersonated(user);
}

export function isTacticVirtualRoom(resource: IResource) {
  return RESOURCE_ID_VIRTUAL_ROOMS.includes(resource.id ?? '');
}

export function isSingleUserDashBeta(user?: AuthenticatedUser) {
  return user && ORG_USER_DASH_BETA.includes(user?.user?.id ?? '');
}

export function isOldDashboardDefault(user?: AuthenticatedUser) {
  return (
    user && ORG_USER_OLD_DASHBOARD_AS_DEFAULT.includes(user?.user?.id ?? '')
  );
}

export function isNoPastReservations(user?: AuthenticatedUser) {
  return (
    user &&
    ORG_SLUG_NO_PAST_RESERVATIONS.includes(user?.organization?.slug ?? '')
  );
}

export function isRBFeature(user?: AuthenticatedUser) {
  return user && ORG_SLUG_RB_FEATURES.includes(user?.organization?.slug ?? '');
}

export function isHpe(user?: AuthenticatedUser) {
  return ['hpe', 'hpe-test'].includes(user?.organization?.slug ?? '');
}

export function allowShowDashboardStats(user?: AuthenticatedUser) {
  return isOrganizationAdmin(user) || !isHpe(user);
}

export function isMapAssignment(user?: AuthenticatedUser) {
  return !!user;
}

export function isDocFeatureEnabled(user?: AuthenticatedUser) {
  return user && ORG_SLUG_DOC_FEATURE.includes(user?.organization?.slug ?? '');
}

export function isSessionReplayForcedForUser(user?: AuthenticatedUser) {
  if (!user) {
    return;
  }
  if (user?.user?.is_being_impersonated) {
    return;
  }
  return FORCE_SESSION_REPLAY_USERS.includes(user?.user?.id ?? '');
}

export type DefaultHoursForOffice = {
  startHour: number;
  endHour: number;
  meetingRoomDuration: number;
  deskDuration: number;
  endMinutes: number;
  startMinutes: number;
  overrideOperationalHours?: boolean;
};

const OFFICES_CUSTOM_DEFAULT_HOURS: {
  [officeId: string]: DefaultHoursForOffice;
} = Object.freeze({
  // by orange - Menara Jamsostek
  'offi_edd3295a-bc3b-4dc4-9f3d-efbd39db5058': {
    startHour: 9,
    endHour: 18,
    meetingRoomDuration: 1,
    deskDuration: 9,
    endMinutes: 0,
    startMinutes: 0
  },
  // yourdigitalresource - HQ 3rd Floor
  'offi_b5dd8532-e69b-40b9-9c09-16d943b293a3': {
    startHour: 8,
    endHour: 17,
    meetingRoomDuration: 1,
    deskDuration: 9,
    endMinutes: 0,
    startMinutes: 0
  },
  // yourdigitalresource - PTO
  'offi_a3278387-fb15-4e84-a9c7-e33368a3dd55': {
    startHour: 8,
    endHour: 17,
    meetingRoomDuration: 1,
    deskDuration: 9,
    endMinutes: 0,
    startMinutes: 0
  },
  // Palermo Cloudy dev
  'offi_d0831843-e7e3-42bb-81bf-af8a640ef96c': {
    startHour: 8,
    startMinutes: 45,
    endHour: 17,
    endMinutes: 45,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // UPM-MTDC - viewpoint
  'offi_8aa5b248-6a43-4af8-9493-7a309ae7ff70': {
    startHour: 9,
    startMinutes: 0,
    endHour: 18,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Headland Consultancy - headlandconsultancy - Cannon Green
  'offi_2915fea4-69da-4960-ba53-5e2086684c00': {
    startHour: 8,
    startMinutes: 45,
    endHour: 17,
    endMinutes: 45,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // The Foursquare Church - LCO Office
  'offi_4e52d9a2-01d7-4f7c-b45c-ddff01e4880f': {
    startHour: 8,
    startMinutes: 0,
    endHour: 16,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 8
  },
  // The Foursquare Church - ASM Office
  'offi_0af66851-cd02-4b13-a759-7e5c65fd45ba': {
    startHour: 8,
    startMinutes: 0,
    endHour: 16,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 8
  },
  // Jumia - Jumia HQ
  'offi_12378e35-3145-4bb0-9efe-98c35c3791eb': {
    startHour: 9,
    startMinutes: 0,
    endHour: 18,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Luma Pictures HQ
  'offi_ebdeab1c-b1a3-4c70-8c55-1ff810fb7406': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Arabian Horse Association -  Arabian Horse Association HQ
  'offi_a0c49eb4-1445-4def-a6ac-5862e9ec28e4': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // huber technology - HQ
  'offi_86cdee75-1c7c-4d76-ba70-e488255e7474': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // K-Line - Sand
  'offi_9c2d6a6f-bcbc-4424-ad67-22328d7aae53': {
    startHour: 7,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 10
  },
  // K-Line - HWY 48
  'offi_6bb00d90-5515-493e-930f-c0c857399b6a': {
    startHour: 7,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 10
  },
  // Goodfellows - Huvu
  'offi_4128d0be-f212-4482-8c57-83ae1fedb72a': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Triland - London
  'offi_64823d97-f8aa-43c7-814e-71e4bcd65c4f': {
    startHour: 7,
    startMinutes: 30,
    endHour: 17,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 10
  },
  // Greenhouse - London
  'offi_b658fba0-a7b1-43d1-a5c3-fc3af8aecb61': {
    startHour: 8,
    startMinutes: 0,
    endHour: 18,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 10
  },
  // Greenhouse - Bristol
  'offi_f2d7882b-457e-48c0-a932-423ffc7b6875': {
    startHour: 8,
    startMinutes: 0,
    endHour: 18,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 10
  },
  // Vitas - Corp
  'offi_8957cc1f-b9f8-41d9-bccc-991cd0a0f1b7': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Ctnsy - Barrie
  'offi_a052d851-761f-4fff-9ac9-c366ec4fbefb': {
    startHour: 8,
    startMinutes: 30,
    endHour: 16,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 8
  },
  // Ctnsy - Oak
  'offi_59b7efab-86e6-4f87-813a-ef6b05a797d6': {
    startHour: 8,
    startMinutes: 30,
    endHour: 16,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 8
  },
  // Journey - Cheltenham
  'offi_f0edd26c-d120-4fe0-b250-3247fe2503dc': {
    startHour: 8,
    startMinutes: 30,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 8.5
  },
  // U of G - U of G
  'offi_413a756a-2935-4c44-a6aa-d767258e42a7': {
    startHour: 8,
    startMinutes: 30,
    endHour: 16,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 8
  },
  // U of G - Marketing
  'offi_4c09ad91-b24c-4344-989f-d11570267cb0': {
    startHour: 8,
    startMinutes: 30,
    endHour: 16,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 8
  },
  // Red Bull Slovakia - Bratislava
  'offi_a4f557e5-0c26-4122-bf58-9c2b4c8c655a': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Red Bull Hungary - Budapest
  'offi_824b2340-4a52-4fd4-bf0b-c88fc1f09c25': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Red Bull Italy - Milan
  'offi_8327461f-79c9-484f-977c-f1c2df80a4eb': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // EHC Munich
  'offi_81b8a310-5358-4010-bdcd-9519ffef5f11': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // RBMH Wals
  'offi_b1bae478-2aa0-4cbd-ba34-15cdcbc1a643': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // RBMH Krieau
  'offi_1e1dddf4-cc0b-40c1-8216-1f125c90e2fc': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // ELS Building 5
  'offi_4f9d23a3-0bac-4736-b416-59de7315282e': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // ELS Building 4
  'offi_268b2acf-0e4c-4a44-b399-441df4695fac': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // ELS Building 3
  'offi_6f09bdd6-e185-403d-ae44-5d025acd13f4': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // ELS Building 2
  'offi_5a1b16e0-c883-412c-a65e-aa6213975282': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // ELS Building 1
  'offi_4b9544b2-1a58-43fe-9073-df2d94e682d9': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // FSL Container City
  'offi_98a44242-422c-4bd7-87dd-52cd24c53863': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // FSL Haupthaus
  'offi_e2cb959e-e9d2-4e56-8604-b8e92f40f760': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // FSL Building 7
  'offi_4cb7e48a-17fa-42b1-950c-91aebf7c577c': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // FSL Building 4
  'offi_42e31e1d-cce8-4b39-8bd7-81e30ccfc9fd': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Slovakia Parking
  'offi_22d50608-6e12-404e-8443-e965f7aff959': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Mackenzie Portland
  'offi_9b1ad845-536d-476b-8d83-32ba36807689': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Mackenzie Seattle
  'offi_ccf3c16d-9d8e-4da8-a9eb-5d6ad7ef7c6a': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Mackenzie Vancouver
  'offi_91cf6c97-3278-4037-905c-2f7c144b2262': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // NDC HQ
  'offi_22fceaa9-9b62-4ccc-9410-f6ed3755887f': {
    startHour: 8,
    startMinutes: 0,
    endHour: 16,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 8.5
  },
  // Consid Malmö HQ
  'offi_0be15109-fef5-4d3e-aef3-9f8df7ae3971': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // LATU - Los Pinos
  'offi_503f7d28-711e-4728-af37-58cf031b6641': {
    startHour: 9,
    startMinutes: 0,
    endHour: 18,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Paycheck HQ
  'offi_cbcdb4a7-38f5-48f9-a624-2bdd3cb64b4b': {
    startHour: 9,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 8.5
  },
  // DT Global HQ - Melbourne
  'offi_4dc57c49-7046-43ac-bf46-de45917791ea': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // DT Global PNG Corp Office - Port Morsby
  'offi_00290162-caa0-4d65-a95c-9c70574bb7ec': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // DT Global Harbourside South - Port Morsby
  'offi_98b915b9-0bd9-4790-a5dd-cfe598ec11ff': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Sonata Bank Brentwood Office
  'offi_7e60820d-e44e-47bd-b32e-1d539a0e5b78': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC - Gonzales HQ
  'offi_46347ea6-4a02-4ccf-aafd-1633c9f2836c': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC - Western Operations Center
  'offi_311b760e-cdd3-4624-ab03-9ff0fd44d219': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC - Seguin Customer Service
  'offi_24900a13-5d44-40e2-8a06-c181a226aa0f': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC - Southern Operations Center
  'offi_0f4b36df-2730-457d-a09d-d154e8f966ce': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC - Schertz
  'offi_cf8e585d-a9b2-410d-9bea-d5c2baa7cc7f': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC - Lavernia Customer Service
  'offi_190c0ed1-1b85-4dcd-acd5-82dbf4976f64': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC - Schertz Warehouse
  'offi_888e03ad-6931-4c94-910d-fdc2df45aea9': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // GVEC -Cuero Customer Service
  'offi_5502429f-bb7b-443a-a519-da9fb9f63807': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // P&A Group HQ
  'offi_4c59ddb2-52d4-48a4-b3e6-463d28cad09c': {
    startHour: 8,
    startMinutes: 30,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 8.5
  },
  // Sosland Publishing - slug = sosland
  'offi_dc29cd52-63d6-4c9e-a5b1-f0cd08d104f4': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // DMA -
  'offi_718c4245-cf74-40e6-8cb7-171ebc15be7f': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // DMA -
  'offi_ab39465c-227c-4377-8399-a2e2f634114a': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Datavant NYC
  'offi_ff5a3b92-df42-43f0-b003-e658652092e4': {
    startHour: 8,
    startMinutes: 0,
    endHour: 19,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 11
  },
  // Enternest - NEST of Florida
  'offi_4c6ccd37-97d6-40d6-8d6f-a1718a881fc2': {
    startHour: 7,
    startMinutes: 0,
    endHour: 18,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 11
  },
  // Comet - Hamburg
  'offi_119a19af-b1d5-4490-8582-f40c71b96b5d': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Comet - Comet Flamatt HQ1 (Switzerland)
  'offi_464f74eb-db78-430f-a1a1-cb363fd40e78': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Comet - Comet Flamatt HQ2 (Switzerland)
  'offi_cb1600b0-5c01-4240-b41b-69dfa11353c6': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // Comet - San Jose
  'offi_3bf009e7-983f-430e-95af-bf167dc84dca': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  },
  // d4c - Delivering 4 Customers HQ
  'offi_bf715f49-c941-492d-ab8c-4b4809aeea3e': {
    startHour: 8,
    startMinutes: 30,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 8.5
  },
  // City of Somerville
  'offi_175c709a-46b6-4a9a-9afc-1dc2e9e63d77': {
    startHour: 8,
    startMinutes: 30,
    endHour: 19,
    endMinutes: 30,
    meetingRoomDuration: 1,
    deskDuration: 11
  },
  // Dot Foods
  'offi_4bb3beaf-ca8d-4b05-8db5-cddd1521219d': {
    startHour: 8,
    startMinutes: 0,
    endHour: 17,
    endMinutes: 0,
    meetingRoomDuration: 1,
    deskDuration: 9
  }
});

export function getDefaultsHoursForOffice(
  officeId?: string
): DefaultHoursForOffice {
  const def = OFFICES_CUSTOM_DEFAULT_HOURS[officeId ?? ''];
  return def
    ? { ...def, overrideOperationalHours: true }
    : {
        // Defaults
        startHour: 9,
        startMinutes: 0,
        endMinutes: 0,
        endHour: 17,
        meetingRoomDuration: 1,
        deskDuration: 8,
        overrideOperationalHours: false
      };
}

const NO_USER_INTEGRATION_ORGS = Object.freeze({
  'orgz_e92857ae-c10b-48a0-8e47-5608a9b60a5c': 'veeam'
});

const SKIP_MAP_CLICK_ORG = Object.freeze(['instructure']);

export function isHealthModuleRestrictionsEnabled(
  user?: AuthenticatedUser,
  office?: Office
) {
  return user && !!office?.health_setting;
}

export function isHealthModuleEnabled(user?: AuthenticatedUser) {
  return !!user && !!user?.organization?.health_module_enabled;
}

export function isHealthAdmin(user?: AuthenticatedUser) {
  if (user?.user?.organization_user.health_is_manager) {
    return true;
  }
  return false;
}

/**
 * Shows a custom message for the currentOffice, not the default office
 */
export function getCustomOfficeDashboardMessage(officeId: string) {
  return officeId in CUSTOM_OFFICE_BANNER_MESSAGES
    ? CUSTOM_OFFICE_BANNER_MESSAGES[
        officeId as keyof typeof CUSTOM_OFFICE_BANNER_MESSAGES
      ]
    : '';
}

const CUSTOM_OFFICE_BANNER_MESSAGES = {
  'offi_7e9bad16-c43f-4cc8-bf5c-ccc670ba2283':
    'Reminder to close all doors and shut off all lights', // 1010Data, NYC
  'offi_329d0b4a-9d68-42fa-9d4f-b11770ba4106':
    'Reminder to close all doors and shut off all lights', // Gondor, Main Office 3
  'offi_2796ada9-a851-4b8a-aabc-caaa04887eea':
    "Please don't forget to bring a mouse and laptop charger!", // Nitro Toronto
  'offi_cea10108-9e5f-4267-b4aa-b938d46af296':
    "Please don't forget to bring a mouse and laptop charger!" // Nitro Dublin
};

const FACILITIES_TICKETS = [
  'orgz_82512be1-a752-4723-bc18-609a84a3b067' // netdocuments
];

export function isFacilityTicketEnabled(organizationId: string) {
  return FACILITIES_TICKETS.includes(organizationId);
}

export function isTacticStaff(user?: AuthenticatedUser) {
  return (
    user?.user?.email &&
    (user.user.email.endsWith('@gettactic.com') ||
      user.user.email.endsWith('@tactic-staging.com') ||
      user.user.email.endsWith('@gettactic.onmicrosoft.com') ||
      process.env.NODE_ENV === 'development')
  );
}

export function isTacticStaffOrImpersonated(user?: AuthenticatedUser) {
  return isTacticStaff(user) || user?.user?.is_being_impersonated;
}

export function isOrganizationAdmin(user?: AuthenticatedUser) {
  return user?.user?.organization_user.roles.includes('owner');
}

export function isLabelFieldEnabled(user?: AuthenticatedUser) {
  return isTacticStaffOrImpersonated(user);
}

export function isRecurringEnabled(user?: AuthenticatedUser) {
  return (
    isTacticStaff(user) ||
    user?.organization?.features.includes('recurring') ||
    isSingleUserDashBeta(user)
  );
}

export function isRecurringDeskDisabled(user?: AuthenticatedUser) {
  return user?.organization?.features.includes('recurring.disable.desk');
}

export function isRecurringRoomDisabled(user?: AuthenticatedUser) {
  return user?.organization?.features.includes('recurring.disable.room');
}

export function showRecurring(
  resourceType: ResourceType,
  authenticatedUser: AuthenticatedUser,
  office: Office
) {
  const canScheduleRecurring =
    authenticatedUser.user?.organization_user.can_schedule_recurring;
  const canScheduleRecurringEnabled =
    authenticatedUser.user?.organization_user.can_schedule_recurring_enabled;

  // Admin only
  if (canScheduleRecurring) {
    return true;
  }

  // If not an admin, check office + resource type combination
  if (canScheduleRecurringEnabled) {
    if (!office.allow_recurring) {
      return false;
    }

    // Either allow_recurring_types is null, an empty array, or contains the resource type
    if (
      !office.allow_recurring_types ||
      (office.allow_recurring_types &&
        (office.allow_recurring_types.length === 0 ||
          office.allow_recurring_types.includes(resourceType)))
    ) {
      return true;
    }
  }

  return false;
}

export function isWorkingLocationsEnabled(user?: AuthenticatedUser) {
  return (
    isTacticStaff(user) ||
    user?.organization?.features.includes('dashboard.new') ||
    isSingleUserDashBeta(user)
  );
}

export function orgRequiresReservationAfterPickingAnOffice(
  user?: AuthenticatedUser
) {
  return ORG_SLUG_REQUIRES_RESERVATION_AFTER_PICKING_WL.includes(
    user?.organization?.slug ?? ''
  );
}

export function isTemporarySkipMapClickOrg(user?: AuthenticatedUser) {
  return user && SKIP_MAP_CLICK_ORG.includes(user?.organization?.slug ?? '');
}

export function isPersonalIntegrationsDisabled(user?: AuthenticatedUser) {
  return (
    user?.organization !== null &&
    NO_USER_INTEGRATION_ORG_IDS.find((x) => x === user?.organization?.id)
  );
}

/**
 * ID's exported to separate pages
 */

export const NO_USER_INTEGRATION_ORG_IDS = Object.freeze(
  Object.keys(NO_USER_INTEGRATION_ORGS)
);

/**
 * Hide restriction / permissions UI for zones until API is ready.
 * See https://github.com/gettactic/api/issues/69
 */
export const ZONES_RESTRICTED_UI = false;
